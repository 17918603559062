

function Homepage() {
  return (
    
    <div>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>Bootstrap Resume Page Template</title>
    {/* Bootstrap */}
    <link href="./bootstrap-4.4.1.css" rel="stylesheet" />
    <div className="container">
      <hr />
      <div className="row">
        <div className="col-6">
          <h1 className="text-color-Red">Under Construction&nbsp;</h1>
        </div>
        <div className="col-6">
          <p className="text-right"><a href>Download my Resume</a></p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <div className="media">
            <img className="mr-3" src="images/115X115.gif" alt="Generic placeholder image" />
            <div className="media-body">
              <h5 className="mt-0">Software Developer</h5>
              Bachelor of Computer Science and engineering and embedded systems development graduate. A technical mindset and excellent logical problem-solving thinking. Have experience in software development, web applications and automation that has helped in increasing productivity and efficiency. Excellent team player, self-learner, and ability to grasp new technologies quickly. Interested in software development areas that include web development, automotive, videos games and embedded systems.
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <div className="row justify-content-md-around m-1">
            <address>
              <strong>Mannatdeep&nbsp;</strong><br />
              Ontario, Canada              <br />
              <a href="mailto:#">mannat02@hotmail.com&nbsp;</a>
            </address>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h2>Education</h2>
          <hr />
          <div className="row">
            <div className="col-6">
              <h4>Conestoga College, Canada</h4>
            </div>
            <div className="col-6">
              <h5 className="text-right"><span aria-hidden="true" /> Sep 2018 - Dec 2019</h5>
            </div>
          </div>
          <h5><span className="badge badge-secondary">Embedded Systems Development&nbsp;</span></h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint, recusandae, corporis, tempore nam fugit deleniti sequi excepturi quod repellat laboriosam soluta laudantium amet dicta non ratione distinctio nihil dignissimos esse!</p>
          <div className="row">
            <div className="col-6">
              <h4>Punjab Technical University</h4>
            </div>
            <div className="col-6">
              <h5 className="text-right"><span aria-hidden="true" /> Aug 2013 - Jul 2017</h5>
            </div>
          </div>
          <h5><span className="badge badge-secondary">Bachelors of Computer Science&nbsp;</span></h5>
          <p>&nbsp; &nbsp;</p>
        </div>
        <div className="col-md-6 col-sm-12">
          <h2>Skill Set</h2>
          <hr />
          <div className="progress mt-4">
            <div className="progress-bar bg-success" role="progressbar" aria-valuenow={85} aria-valuemin={0} aria-valuemax={100} style={{width: '85%'}}> JAVA&nbsp;</div>
          </div>
          <div className="progress mt-4">
            <div className="progress-bar bg-success" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} style={{width: '80%'}}> CSS</div>
          </div>
          <div className="progress mt-4">
            <div className="progress-bar bg-success" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '70%'}}> JAVASCRIPT</div>
          </div>
          <div className="progress mt-4">
            <div className="progress-bar bg-info" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: '60%'}}> PHP</div>
          </div>
          <div className="progress mt-4">
            <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={55} aria-valuemin={0} aria-valuemax={100} style={{width: '55%'}}> WORDPRESS</div>
          </div>
          <div className="progress mt-4">
            <div className="progress-bar bg-danger" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}> PHOTOSHOP</div>
          </div>
          <div className="progress mt-4">
            <div className="progress-bar bg-danger" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}> ILLUSTRATOR</div>
          </div>
        </div>
      </div>
      <hr />
      <h2>Work Experience</h2>
      <hr />
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="row">
            <div className="col-5">
              <h4>HCLTech, Canada</h4>
            </div>
            <div className="col-6">
              <h5 className="text-right"><span aria-hidden="true" /> Oct 2022 -Present</h5>
            </div>
          </div>
          <h5><span className="badge badge-secondary">Software Developer</span></h5>
          <p>Joined as Software developer.Completed a web developement project for a reputated investment bank. Currently developing services for internal framework for another bank.&nbsp; &nbsp; &nbsp;&nbsp;</p>
          <ul>
            <li>Developing and deploying new services and providing support for internal framework with help of tools like UDeploy, Autosys, Oracle SQL Developer.</li>
            <li>Developed restful web services using Spring Boot.</li>
            <li> Creating and connecting Relational Databases to Web applications, such as MySQL and MS SQL Server.</li>
            <li>Improve and create secured frontend for client’s dashboard using ReactJs and APIs like Okta Authentication and Stripe payment gateway.</li>
            <li>Working within agile team throughout whole software lifecycle and help CI/CD process using Jenkins and SonarQube.&nbsp;</li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="row">
            <div className="col-5">
              <h4>Tigercat Industries</h4>
            </div>
            <div className="col-6">
              <h5 className="text-right"><span aria-hidden="true" /> Jun 2021 - Oct 2022</h5>
            </div>
          </div>
          <h5><span className="badge badge-secondary">Web/Embedded Software Developer</span></h5>
          <p>Contributed to software development for Forestery machines HMI systems. Also, designed and programmed PLCs for Brewery system  for HDP (Macdonald Steels Division).&nbsp; &nbsp;&nbsp;</p>
          <ul>
            <li>Designed and developed new Automation systems, and features using CLICK, Productivity PLC capabilities.</li>
            <li>Redeveloped bought hardware systems to minimize processor and memory utilization using C/C++.</li>
            <li> Developed new SCADA systems as per customer’s requirements.</li>
            <li> Mapping PLC drivers to API and designing new dashboards for data analytics using Java Web tools, C++.&nbsp;</li>
            <li>Participated in architecture design to make project implementation simple and efficient.&nbsp;</li>
          </ul>
        </div>
      </div>
      <hr />
      <h2>Portfolio</h2>
      <hr />
      <div className="container">
        <div className="row text-center">
          <div className="col-sm-4 col-12 p-0"><img className="img-thumbnail" src="images/300X200.gif" alt="" /></div>
          <div className="col-sm-4 col-12 p-0"><img className="img-thumbnail" src="images/300X200.gif" alt="" /></div>
          <div className="col-sm-4 col-12 p-0"><img className="img-thumbnail" src="images/300X200.gif" alt="" /></div>
        </div>
        <div className="row text-center">
          <div className="col-sm-4 col-12 p-0"><img className="img-thumbnail" src="images/300X200.gif" alt="" /></div>
          <div className="col-sm-4 col-12 p-0"><img className="img-thumbnail" src="images/300X200.gif" alt="" /></div>
          <div className="col-sm-4 col-12 p-0"><img className="img-thumbnail" src="images/300X200.gif" alt="" /></div>
        </div>
      </div>
      <hr />
      <h2>Contact</h2>
      <hr />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  col-12 jumbotron">
            <form>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" placeholder="Name" />
              </div>
              <div className="form-group">
                <label htmlFor="email">E-Mail</label>
                <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" aria-describedby="emailHelp" />
                <span id="emailHelp" className="form-text text-muted" style={{display: 'none'}}>Please enter a valid e-mail address.</span>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea rows={10} cols={100} className="form-control" id="message" name="message" placeholder="Message" aria-describedby="messageHelp" defaultValue={""} />
                <span id="messageHelp" className="form-text text-muted" style={{display: 'none'}}>Please enter a message.</span>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <hr />
      <footer className="text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Copyright © Mannatdeep. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
    {/* jQuery (necessary for Bootstrap's JavaScript plugins) */}
    {/* Include all compiled plugins (below), or include individual files as needed */}
  </div>
   
  );
}

export default Homepage;
