import React from 'react';
import logo from './logo.svg';


import './App.css';
import NavigationBar from './Nav';

import ResumePage from './ResumePage';
import MovingCircle from './Movingcircle';
import Homepage from './Homepage';

function App() {
  return (
    <React.Fragment>
      
      <Homepage/>
    </React.Fragment>
    
  );
}

export default App;
